.face {
  animation: 2s ease-in-out infinite alternate shake;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.navigation {
  border-bottom: 1px solid #fff;
}

.nav-feature .subtitle {
  padding: 0;
  background-color: transparent;
  font-family: "Titling Normal";
  text-transform: uppercase;
  font-size: .7em;
  font-weight: 900;
  line-height: 1em;
  display: inline-block;
  font-style: italic;
  letter-spacing: 1px;
  width: 100%;
  top: -3px;
  position: relative;
}

.is-hidden {
  display: none;
}
.ad-block {
  background: white;
}

.map-box {
  min-height: 400px;
}

@media print { 
  .pagebreak { 
    page-break-before: always; 
  }
  .map-box {
    min-height: 500px;
  }
  .leaflet-control-attribution {
    display: none;
  }
  #typebot-bubble {
    display: none;
  }
}

.nav-feature .title {
  font-family: "Titling Skyline Standard";
  text-transform: uppercase;
  display: block;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 2.5em;
  line-height: 1em;
  border-bottom: solid 3px rgba(255, 255, 255, .5);
  padding-bottom: 10px;
  padding-top: 10px;
}

.nav-featured-link {
  font-family: 'Titling Normal';
  font-size: .7em !important;
  text-transform: uppercase;
  font-weight: 900;
  padding: 0px;
  border-radius: 0;
  background-color: transparent !important;
  margin: 5px 0 5px 0;
  height: auto;
  font-weight: 700;
  display: block;
}

.nav-link {
  cursor: pointer;
  text-decoration: none;
  outline: transparent solid 2px;
  outline-offset: 2px;
  font-size: 1.25em;
  text-transform: none;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: 'Roboto';
  margin: 0;
  padding: 0;
  line-height: 1.125em;
}

.markdown-react h3 {
  font-size: 0.8em !important;
}

hr {
  padding: 6px;
}

p {
  font-family: 'Roboto';
  font-size: 16px;
}

a:hover {
  opacity: .8;
  transition: all ease-in-out .2s;
}

.header-logo {
  margin-bottom: -40px;
  position: relative;
  z-index: 999;
  margin-left: -40px;
  max-width: 100px;
}

a.css-yfvjmk:hover {
  overflow: visible !important;
  position: relative !important;
  z-index: 999 !important;
  opacity: 1 !important;
}

.experiences .experiences-header h2 {
  color: #17335b !important;
  text-shadow: none;
}

.experiences .light-bottom {
  position: relative;
}

.experiences .light-bottom:before {
  content: "";
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #3C5A7E;
  position: absolute;
  left: 50%;
  margin-left: -16px;
  top: -16px;
}

.experiences .dark-top {
  position: relative;
}

.experiences .dark-top:before {
  content: "";
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #17325B;
  position: absolute;
  left: 50%;
  margin-left: -16px;
  bottom: -16px;
}

.drop-zone {
  height: 150px;
  padding: 55px 10px 10px 10px;
  text-align: center;
}

.drop-on {
  border: 2px solid #8fbd99;
}

.drop-off {
  border: 2px solid #FF2C55;
}

.error {
  color: #FF2C55;
}

.custom-file-upload {
  color: #FF2C55;
  font-size: 0.7em;
  cursor: pointer;
}

.css-9b371k a {
  font-weight: 100;
}

.css-1jpznco .chakra-tabs__tablist button {
  font-family: 'Titling Comp Medium' !important;
  font-weight: 100;
  letter-spacing: 1px;
  font-size: 1em;
  margin: 0;
  padding-top: 24px;
}

.css-1jpznco .chakra-tabs__tablist button:hover {
  color: #7d9774;
}

.map-position {
  position: sticky;
  position: --webkit-sticky;
  top: 0px;
}

.list-group {
  margin-left: 25px;
}

.responsiveTable td .tdBefore {
  display: none;
}

.chakra-ui-dark tr:nth-of-type(odd) td {
  background: #222 !important;
}

.masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -20px;
  width: auto;
}

.masonry-grid_column {
  padding-left: 20px;
  background-clip: padding-box;
}

.masonry-grid_column>div {
  margin-bottom: 20px;
}

.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  padding: 20px 15px 20px 15px;
  font-size: 14px;
  border: 1px solid #eee;
  border-radius: 15px;
  min-width: 300px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #FF2C55;
  color: white;
  font-size: 14px;
  display: inline-block;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}

.header {
  font-family: 'Briston' !important;
  font-size: 2em;
  margin: 0px;
  padding: 0px;
  line-height: 1em;
  text-align: left !important;
}

.signupForm {
  position: relative;
  margin-top: 10px;
  display: inline-block;
}

.signupForm.getyourguide {
  position: absolute;
  width: 80%;
  bottom: 28px;
}

.signupForm .arrowbutton {
  position: absolute;
  background-color: #8a8ae5;
  top: 5px;
  right: 4px;
  border-radius: 20px;
  margin: 0;
  height: 30px;
  z-index: 999;
}

.signupForm .arrowbutton img {
  max-width: 100px;
}

.dispatch-articles {
  padding-bottom: 150px;
}

.dispatch-articles h3 {
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1em;
  font-size: 1.35em;
}

.dispatch-articles a.chakra-link {
  text-transform: uppercase;
  font-family: 'Titling Comp Standard';
  letter-spacing: .05em;
  color: #7d9774;
  margin: 0;
  padding: 5px 0 0 0;
  line-height: 1em;
}

.dispatch-articles a.chakra-link:hover {
  color: #17335b;
}

.dispatch-articles p {
  margin: 0;
}

.dispatch-articles strong {
  font-family: 'Titling Wide Standard';
  font-size: .6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  ;
  color: #7d9774;
}

.dispatch-block {
  background: rgb(21,51,91);
background: linear-gradient(0deg, rgba(21,51,91,1) 0%, rgba(21,51,91,1) 70%, rgba(21,51,91,0) 100%);
  padding-top: 200px;
  margin-top: -260px;
  position: relative;
}

.dispatch-block:before {
  display: inline-block;
  width: 100%;
  background-image: url('../assets/images/blue-map.png');
  background-size: cover;
  background-position: center top;
  content: " ";
  height: 100vh;
  position: absolute;
  left: 0px;
  top: 0px;
}

.annual-events {
  background-image: url('../assets/images/events-bg.jpg');
  background-size: cover;
  background-position: center top;
  padding-top: 50px;
}

.annual-events .chakra-tabs__tablist {
  font-family: 'Titling Normal';
  letter-spacing: 1px;
  font-weight: 100;
  overflow: hidden !important;
}

.annual-events .chakra-tabs__tablist button {
  font-weight: 100;
  letter-spacing: 1px;
}

.annual-events .events-header h2 {
  line-height: .7em;
  font-size: 4em;
}

.annual-events .chakra-tabs__tab-panel {
  max-width: 90%;
  display: block;
  margin: 0 auto;
}

.annual-events .slick-slider h2 {
  font-family: 'Titling Comp Medium';
  text-transform: uppercase;
  letter-spacing: .02em;
}

.annual-events .slick-slider h4 {
  text-align: center;
  font-family: 'Briston' !important;
  font-size: 2em;
  line-height: 1.5em;
}

.annual-events .slick-slider h4 span {
  font-family: 'Titling Wide Bold' !important;
  text-transform: uppercase;
  font-weight: 900;
  font-size: .3em;
  display: block;
  line-height: 1em;
  margin-top: 0.5em;
}

.date-month {
  font-family: 'Roboto';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8em;
  display: block;
  line-height: 1em;
  margin-top: 0.1em;
}

.date-day {
  text-align: center;
  font-family: 'Titling Normal';
  font-weight: 900;
  font-size: 1.25em;
  line-height: 1.25em;
  padding-bottom: 0.1em;
}

.experiences {
  background-image: url('../assets/images/experiences.jpg') !important;
  background-size: cover;
  background-position: center top;
}

::placeholder {
  font-family: 'Titling Wide Standard';
  font-size: .8em;
  letter-spacing: 1px;
}

.ReactTags__selected button.ReactTags__remove {
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
}

.calendar-container h2 {
  padding-bottom: 0;
  font-size: 1.75em;
  margin-top: 15px;
}

.experiences .featured-experience {
  text-align: left;
}

.experiences h2 {
  color: #bdedde;
  font-size: 3.5em;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, .6);
}

.experiences h3 {
  font-size: 1.5em;
}

.experiences h4 {
  font-family: 'Titling Wide Standard';
  font-size: .6em;
  margin: 5px 0;
}

.experiences a.chakra-link {
  font-family: 'Titling Comp Standard';
  letter-spacing: 1px;
}

.experiences a.chakra-link:hover {
  color: #88B597;
}

.experiences .featured-experience a.chakra-link {
  font-family: 'Titling Wide Standard';
  margin-top: 10px;
  font-size: .6em;
}

.experiences .featured-experience h3 {
  font-family: 'Titling Comp Medium';
  line-height: 1.125em;
  font-size: 1.325em;
  letter-spacing: 1px;
}

.category-wrapper h2 {
  font-family: 'Titling Comp Standard';
  text-transform: uppercase;
  letter-spacing: 1px;
}

.explore {
  background-image: url('../assets/images/green-map-bg.jpg');
  background-size: cover;
}

.explore .chakra-tabs__tablist {
  border-top: solid 3px #17335b;
  border-bottom: solid 3px #17335b;
}

.explore .chakra-tabs__tablist button {
  padding: 3px 12px;
  margin: 3px 6px;
  border-radius: 3px;
  font-family: 'Titling Normal';
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 14px;
}

.explore-header .explore h2 {
  margin: 0;
  padding: 0;
  line-height: 1em;
  font-size: 3.5em;
}

.explore p {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.explore h3 {
  margin: 0;
  padding: 0;
  line-height: 1em;
  font-size: 3em;
}

.explore strong {
  font-family: 'Titling Wide Standard';
  text-transform: uppercase;
  font-size: .7em;
  letter-spacing: .03em;
  display: block;
  margin: 5px 0 10px;
}

.explore p {
  margin-bottom: 15px;
}

.explore .vertical-divider {
  height: 100%;
  width: 3px;
  background-color: #17335b;
  min-height: 40px;
}

.footer .footer-link-wrapper p,
.footer .footer-link-wrapper a {
  display: inline-block;
  width: 100%;
  font-family: 'Titling Wide Standard';
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #bdedde;
  font-size: .65em;
  text-align: left;
  margin: 0 10px 0 0;
  padding: 0;
}

.footer .footer-link-wrapper a:hover {
  color: #fff;
  opacity: 1;
}

.footer-subhead {
  font-family: 'Titling Wide Standard';
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: .7em;
}

.footer-subhead.connect {
  color: #fff;
}

.footerlogos {
  display: flex !important;
  justify-content: space-between;
}

.footerlogos img,
.footerlogos a {
  display: inline-block;
  float: none;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

.datepicker-calendar {
  background: white;
}

/* Full Calendar */
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}

a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}

a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}

div.fc-daygrid-event-harness {
  top: unset;
}

.fc-daygrid-event-harness .fc-daygrid-event-harness-abs {
  top: 0px;
  bottom: unset;
}

/* .fc-theme-standard td . {
} */
.fc-theme-standard .fc-scrollgrid {
  border: 0px solid transparent !important;
}

.fc-col-header-cell.fc-day.fc-day-mon {
  border-right: 0px;
  border-left: 0px;
}

.fc-col-header-cell.fc-day.fc-day-tue {
  border-left: 0px;
  border-right: 0px;
}

.fc-col-header-cell.fc-day.fc-day-wed {
  border-right: 0px;
  border-left: 0px;
}

.fc-col-header-cell.fc-day.fc-day-thu {
  border-left: 0px;
  border-right: 0px;
}

.fc-col-header-cell.fc-day.fc-day-fri {
  border-right: 0px;
  border-left: 0px;
}

.fc-col-header-cell.fc-day.fc-day-sat {
  border-left: 0px;
  border-right: 0px;
}

.fc-col-header-cell.fc-day.fc-day-sun {
  border-left: 0px;
  border-right: 0px;
}

.fc-scrollgrid.fc-scrollgrid-liquid>td {
  border: 0px;
}

.fc .fc-scrollgrid-section>td {
  border: 0;
}

/* React Kanban */
body.chakra-ui-light .react-kanban-column {
  background-color: #edf2f7;
  /* Gray/Gray-100 */
  border-radius: 15px;
}

body.chakra-ui-dark .react-kanban-column {
  background-color: #2d3748;
  /* Gray/Gray-100 */
  border-radius: 15px;
}

.react-kanban-column {
  width: 300px;
}

.react-kanban-card-adder-form {
  width: 100%;
  background-color: red;
  border-radius: 15px;
}

.react-kanban-card-adder-button {
  display: none;
  background-color: red !important;
  border-radius: 15px;
}

/* Text Editor */

body.chakra-ui-light .ql-toolbar.ql-snow {
  border-color: rgb(226, 232, 240);
  /* Gray/Gray-100 */
}

body.chakra-ui-dark .ql-toolbar.ql-snow {
  border-color: rgba(255, 255, 255, 0.16);
  /* Gray/Gray-100 */
}

body.chakra-ui-light .ql-container.ql-snow {
  border-color: rgb(226, 232, 240);
  /* Gray/Gray-100 */
}

body.chakra-ui-dark .ql-container.ql-snow {
  border-color: rgba(255, 255, 255, 0.16);
  /* Gray/Gray-100 */
}

body.chakra-ui-light .ql-snow .ql-picker-label {
  color: rgb(26, 32, 44) !important;
}

body.chakra-ui-dark .ql-snow .ql-picker-label {
  color: rgba(255, 255, 255, 0.92) !important;
}

body.chakra-ui-light .ql-snow .ql-stroke {
  stroke: rgb(26, 32, 44) !important;
}

body.chakra-ui-dark .ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.92) !important;
}

body.chakra-ui-light .ql-snow .ql-fill {
  fill: rgb(26, 32, 44) !important;
}

body.chakra-ui-dark .ql-snow .ql-fill {
  fill: rgba(255, 255, 255, 0.92) !important;
}

.ql-snow .ql-picker-label:hover {
  color: rgb(79, 209, 197) !important;
}

.ql-snow .ql-stroke:hover {
  stroke: rgb(79, 209, 197) !important;
}

.ql-snow .ql-fill:hover {
  fill: rgb(79, 209, 197) !important;
}

.ql-container.ql-snow {
  min-height: 88px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.hero-slider .slick-next {
  right: 50px;
}

.hero-slider .slick-next:before {
  color: #BDEDDF;
  font-size: 50px;
  width: 50px;
  height: 50px;
}

.slick-dispatch .slick-next {
  right: 0px;
}

.slick-dispatch .slick-next:before {
  color: #90BD9A;
  font-size: 50px;
}

.slick-dispatch .slick-prev:before {
  display: none !important;
}

.hero-slider .slick-prev:before {
  display: none !important;
}

.featured-slider .slick-prev {
  left: 75px !important;
  z-index: 999999;
}

.featured-slider .slick-prev:before {
  color: #90BD9A;
  font-size: 50px;
  width: 50px;
  height: 50px;
}

.featured-slider .slick-next {
  right: 50px;
}

.featured-slider .slick-next:before {
  display: none;
}

.event-slider .slick-prev {
  left: 75px !important;
  z-index: 999999;
}

.event-slider .slick-prev:before {
  color: #90BD9A;
  font-size: 50px;
  width: 50px;
  height: 50px;
}

.event-slider .slick-next {
  right: 50px;
}

.event-slider .slick-next:before {
  display: none;
}

.guide-slider .slick-prev:before {
  display: none !important;
}

.guide-slider .slick-next {
  right: 150px;
}

.guide-slider .slick-next:before {
  color: #90BD9A;
  font-size: 50px;
  width: 50px;
  height: 50px;
}

.events-slider .slick-prev {
  left: -50px;
}

.events-slider .slick-prev:before {
  color: #90BD9A;
  font-size: 50px;
  width: 50px;
  height: 50px;
}

.events-slider .slick-next {
  right: -20px;
}

.events-slider .slick-next:before {
  color: #90BD9A;
  font-size: 50px;
  width: 50px;
  height: 50px;
}

.slick-dispatch h2 {
  font-size: 2.5em;
  font-family: 'Titling Comp Standard';
  line-height: 1em;
}

.slick-dispatch strong {
  font-family: 'Titling Wide Standard';
  text-align: left;
  font-size: .7em;
  text-transform: uppercase;
  letter-spacing: .08em;
  margin: 5px 0 10px;
  display: inline-block;
}

.dispatch-counter {
  font-family: 'Titling Comp Standard';
  color: #7d9774;
  font-size: 2em;
  line-height: 1em;
}

.dispatch-counter span {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-style: italic;
  font-size: .5em;
  font-weight: 600;
  display: block;
  line-height: 2.25em;
}

.slick-slider h3 {
  font-family: 'Titling Wide Standard';
  text-align: left;
  font-size: .8em;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.hero-slider {
  position: relative;
}

.slick-gradient:after {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(189, 237, 233, 1) 90%);
  width: 100%;
  height: 8em;
}

.page-slider {
  margin-bottom: -10px;
}

.signals-wrapper {
  
}

@media (max-width: 80em) {
  .featured-slider .slick-prev, .event-slider .slick-prev {
    left: 40px !important;
  }
}

@media (max-width: 62em) {

  .featured-slider .slick-prev, .event-slider .slick-prev {
    left: 25px !important;
  }

  .nav-feature .title {
    margin-top: -20px;
    font-size: 1.5em;
  }

  .dispatch-counter {
    display: none;
  }

  .slick-arrow {
    display: none !important;
  }

}

@media (max-width: 48em) {
  
  .mobile-center {
    text-align: center;
  }

  .chakra-radio {
    float: right;
  }

  .chakra-radio__label {
    font-size: 0.55em !important;
  }

  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none; for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable tr {
    display: inline-block;
    float: left;
    width: 48%;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .responsiveTable tbody tr {
    padding: 0.25em;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */

    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    border-bottom: 1px solid lightgrey !important;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }

  .css-19l9l8p {
    min-height: 230px !important;
  }

  button.chakra-button.css-xslapg {
    font-size: 50px !important;
    padding: 12px !important;
  }

  .css-13etba7 {
    width: 80px !important;
  }

  .dispatch-counter {
    display: none;
  }

  .dispatch-articles h3 {
    line-height: 1;
    font-size: 1em;
    font-family: 'Roboto';
  }

  .dispatch-article:nth-of-type(even) {
    border-right: 0px transparent !important;
  }

  h2.css-1td1pvm {
    line-height: .8em;
  }

  h2.css-igsgp1 {
    font-size: 2.5em;
    line-height: .8em;
  }

  .css-1egfvi7 {
    width: 100% !important;
  }

  .experiences h3 {
    font-size: 1.25em;
    padding: 0 0 10px 0;
    margin: 0;
    font-family: 'Titling Comp Standard';
    text-transform: uppercase;
    line-height: 1em;
  }

  .experiences h4 {
    font-size: .5em;
    padding: 0;
    margin: 0;
    line-height: 1em;
  }

  .experiences a.chakra-link {
    font-size: .8em;
  }

  .css-zhisnr {
    width: 100% !important;
    left: 0 !important;
    margin-left: 0 !important;
    padding: 125px 20px 0 !important;
  }

  h2.css-19elzzj {
    line-height: .8em;
  }

  .your-adventure {
    max-width: 170px;
    height: auto;
    float: right;
    display: inline-block;
    margin: 0;
    position: absolute;
    bottom: 0;
    right: -30px;
  }

  h2.css-3nlaob {
    font-size: 2.5em;
    line-height: 1em;
  }

  .explore .chakra-tabs__tablist {
    display: inline-block;
    width: 100%;
  }

  .explore .chakra-tabs__tablist button {
    display: inline-block;
    width: 100%;
  }

  .explore h3 {
    text-align: center;
  }

  .css-t5vo79 {
    margin-left: 0 !important;
    text-align: center;
  }

  .css-5ax9tk {
    font-size: 2em !important;
    margin: 0;
    padding: 0;
    line-height: 1em !important;
  }

  footer {
    text-align: center !important;
  }

  a.footer-dispatch {
    display: inline-block;
    width: auto;
  }

  .footer-subhead {
    text-align: center;
  }

  .footerlogos {
    display: inline-block !important;
    width: 100% !important;
  }

  .footerlogos img,
  .footerlogos a {
    display: block;
    margin: 20px auto !important;
  }

  .footerlogos .logo.image {
    height: auto;
    width: 220px;
  }

  .copyrights p,
  .css-1x27vgw {
    text-align: center !important;
  }

  a.footer-dispatch {
    background-color: #8989E4 !important;
    color: #17325B;
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: .8em;
  }

  .backtop {
    background-color: #8989E4 !important;
    color: #17325B;
    display: block;
    text-align: center;
    line-height: .8em;
  }

  .chakra-stack.css-znsa3k {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  
  .slick-arrow {
    display: none !important;
  }

  .nav-link {
    white-space: normal;
  }

  .nav-feature .title {
    font-size: 1.5em;
  }

  .dispatch-block {
    margin-top: -280px;
  }

  .more-button {
    padding: 10px !important;
    font-size: 10px !important;
  }

  .fc-toolbar {
    flex-direction: column;
    text-align: center;
  }

  .fc-toolbar .fc-toolbar-chunk {
    margin-bottom: 10px;
  }

  .fc-toolbar .fc-button-group {
    margin-top: 10px;
  }

  .adventure-img {
    display: none;
  }

}

@media (max-width: 30em) { 
  .adventure-img {
    display: none;
  }
}